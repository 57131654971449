import ReactModal from 'react-modal';
import { rem } from 'polished';
import styled, { useTheme } from 'styled-components';

import MinusIcon from '../../static/icons/minus.svg';
import { CloseButton } from '../button';

import GlobalModalStyles from './GlobalModalStyles';

const ModalWrapper = styled.div`
  padding: ${rem(48)};

  h3 {
    font-size: ${rem(40)};
    letter-spacing: ${rem(12)};
    font-family: ${props => props.theme.fonts.MrsEavesSmallCaps};
    margin: ${rem(8)} 0 ${rem(32)};
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    color: ${props => props.theme.colors.red};
  }

  > div:first-child {
    max-width: ${rem(500)};
  }
`;

interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  closeModal?(...args: unknown[]): unknown;
  theme?: 'dark' | 'light';
}

export default function Modal({
  children,
  isOpen,
  closeModal,
  theme,
}: ModalProps) {
  const scTheme = useTheme();

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '1000',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
      maxHeight: '80vh',
      display: 'flex',
      border: `2px solid ${scTheme.colors.red}`,
      background:
        theme === 'dark' ? `${scTheme.colors.gray}` : `${scTheme.colors.white}`,
    },
  };

  return (
    <ReactModal
      appElement={
        typeof document !== 'undefined' && document.querySelector('#___gatsby')
      }
      isOpen={isOpen}
      closeTimeoutMS={300}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Contact Modal"
    >
      <GlobalModalStyles />

      <CloseButton onClick={closeModal}>
        <MinusIcon />
      </CloseButton>

      <ModalWrapper>{children}</ModalWrapper>
    </ReactModal>
  );
}
