import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html, body {
    background: ${props => props.theme.colors.red};
    max-width: 100%;
  }

  a {
    text-decoration: none;
    transition: opacity 0.25s ease-out;
    cursor: pointer;
  }

  button {
    border: 0;
    outline: none;
    background: none;
  }
`;

export default GlobalStyles;
