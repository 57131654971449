import { AnimatePresence, motion } from 'framer-motion';
import styled, { ThemeProvider } from 'styled-components';

import { Footer } from '../components/footer';
import { Header } from '../components/header';
import { theme } from '../theme';

import GlobalStyles from './GlobalStyles';

import 'sanitize.css';
import './fonts.css';

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    min-height: 50vh;
  }
`;

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <LayoutContainer>
        <Header />
        <AnimatePresence mode="wait">
          <motion.main
            initial={{ opacity: 0, y: -0 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -0 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            key="layout"
          >
            {children}
          </motion.main>
        </AnimatePresence>
        <Footer />
      </LayoutContainer>
    </ThemeProvider>
  );
};

export default Layout;
