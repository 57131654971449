const breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

type Breakpoint = keyof typeof breakpoints;
type TypeWidth = 'max' | 'min';

/**
 * @param key - Breakpoint
 * @param type - TypeWidth
 * @param customSize - string
 * @returns string - media query for specified breakpoint
 * @example
 * ${props => props.theme.mediaQuery('md')} {
 *   background-color: red;
 * }
 */
export default (
  key: Breakpoint,
  type: TypeWidth = 'max',
  customSize?: string
) =>
  `@media (${type}-width: ${
    customSize && !key ? customSize : breakpoints[key]
  })`;
