interface Fonts {
  Hermann: string;
  MrsEavesRoman: string;
  MrsEavesSmallCaps: string;
  Garamond: string;
}

export default {
  Hermann: 'Hermann',
  MrsEavesRoman: 'MrsEavesRoman',
  MrsEavesSmallCaps: 'MrsEavesSmallCaps',
  Garamond: 'EB Garamond',
} as Fonts;
