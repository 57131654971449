import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'gatsby';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import isExternal from '../is-external';

interface ButtonProps {
  title: string;
  onClick?(...args: unknown[]): unknown;
  color?: string;
  padding?: string;
  href?: string;
  size?: string;
}

const ButtonWrapper = styled.button<ButtonProps>`
  border: 0;
  outline: 0;
  background-color: ${props =>
    props.color === 'red' ? props.theme.colors.red : props.theme.colors.gray};
  transition: background 0.3s;

  &:hover {
    cursor: pointer;
    opacity: 1;
    background-color: ${props =>
      props.color === 'red'
        ? props.theme.colors.redHover
        : props.theme.colors.grayHover};
  }

  svg {
    width: ${rem(20)};
    height: ${rem(20)};
    margin-left: ${rem(10)};
    position: relative;
    top: -2px;
  }

  ${props =>
    props.size === 'small' &&
    css`
      font-family: ${props => props.theme.fonts.MrsEavesSmallCaps};
      letter-spacing: ${rem(3)};
      font-size: ${rem(16)};
      text-transform: uppercase;
      padding: ${rem(8)} ${rem(16)};
      color: ${props => props.theme.colors.white};
      border-radius: ${rem(60)};
      line-height: 1;
    `}

  padding: ${props => props.padding === 'big' && `${rem(16)}  ${rem(24)}`};
`;

export default function Button({
  title,
  onClick,
  color,
  padding,
  href = null,
  size = 'small',
}: ButtonProps) {
  return (
    <ButtonWrapper
      size={size}
      color={color}
      padding={padding}
      as={href && Link}
      to={href}
      onClick={onClick}
      target={href && isExternal(href) && `_blank`}
      title={title}
    >
      {title}

      {href && isExternal(href) && <OpenInNewIcon />}
    </ButtonWrapper>
  );
}
