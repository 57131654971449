exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-conferences-archive-tsx": () => import("./../../../src/pages/conferences-archive.tsx" /* webpackChunkName: "component---src-pages-conferences-archive-tsx" */),
  "component---src-pages-conferences-tsx": () => import("./../../../src/pages/conferences.tsx" /* webpackChunkName: "component---src-pages-conferences-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-programs-tsx": () => import("./../../../src/pages/programs.tsx" /* webpackChunkName: "component---src-pages-programs-tsx" */),
  "component---src-pages-vision-tsx": () => import("./../../../src/pages/vision.tsx" /* webpackChunkName: "component---src-pages-vision-tsx" */),
  "component---src-templates-archived-conference-tsx": () => import("./../../../src/templates/archived-conference.tsx" /* webpackChunkName: "component---src-templates-archived-conference-tsx" */),
  "component---src-templates-conference-tsx": () => import("./../../../src/templates/conference.tsx" /* webpackChunkName: "component---src-templates-conference-tsx" */)
}

