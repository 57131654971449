import { rem } from 'polished';
import styled, { css } from 'styled-components';

const Container = styled.div`
  width: ${rem(1040)};
  margin: 0 auto;
  max-width: 100%;
  padding: 0;

  ${props => props.theme.mediaQuery('lg')} {
    padding: 0 ${rem(16)};
  }
`;

const bannerBg = css`
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    background-color: ${props => props.theme.colors.red};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    ${props => props.theme.mediaQuery('lg', 'min')} {
      width: 100vw;
      // transform: translateX(calc((100vw - 1012px) / -2));
      transform: translateX(calc((100vw - 1012px) / -2));
    }
  }
`;

export { bannerBg, Container };
