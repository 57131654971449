interface OcaaColors {
  white: string;
  black: string;
  gray: string;
  grayHover: string;
  lightGray: string;
  lighterGray: string;
  darkWhite: string;
  red: string;
  redHover: string;
  pink: string;
}

export default {
  white: '#ffffff',
  black: '#000000',
  gray: '#383838',
  grayHover: '#5a5a5a',
  lightGray: '#909090',
  lighterGray: '#c5c5c5',
  darkWhite: '#e5e5e5',
  red: '#972931',
  redHover: '#c5353f',
  pink: '#D88E93',
} as OcaaColors;
