import { useState } from 'react';
import Modal from 'react-modal';
import { StaticImage } from 'gatsby-plugin-image';
import { rem } from 'polished';
import styled from 'styled-components';

import MinusIcon from '../../static/icons/minus.svg';
import { CloseButton } from '../button';
import { GlobalModalStyles } from '../modal';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '1000',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    maxHeight: '80vh',
    maxWidth: '90%',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
};

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${props => props.theme.mediaQuery('lg')} {
    padding: ${rem(20)};
    padding-top: ${rem(50)};
  }

  ${props => props.theme.mediaQuery('lg', 'min')} {
    padding: ${rem(48)};
  }

  h3 {
    font-family: ${props => props.theme.fonts.MrsEavesSmallCaps};
    margin: ${rem(8)} 0 ${rem(32)};
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    color: ${props => props.theme.colors.red};

    ${props => props.theme.mediaQuery('lg')} {
      font-size: ${rem(20)};
      letter-spacing: ${rem(6)};
    }

    ${props => props.theme.mediaQuery('lg', 'min')} {
      font-size: ${rem(40)};
      letter-spacing: ${rem(12)};
    }
  }

  > div:first-child {
    max-width: ${rem(500)};
  }
`;

const FormWrapper = styled.form`
  text-align: center;

  textarea {
    width: 100%;
    border: 0;
    border-bottom: 2px solid ${props => props.theme.colors.red};
    outline: 0;
    font-family: ${props => props.theme.fonts.Garamond};
    font-style: italic;
    font-weight: 400;
    color: ${props => props.theme.colors.red};

    ${props => props.theme.mediaQuery('lg')} {
      height: ${rem(100)};
      font-size: ${rem(16)};
    }

    ${props => props.theme.mediaQuery('lg', 'min')} {
      height: ${rem(200)};
      font-size: ${rem(24)};
    }
  }

  button {
    font-family: ${props => props.theme.fonts.Garamond};
    font-size: ${rem(28)};
    letter-spacing: ${rem(3)};
    color: ${props => props.theme.colors.red};
    font-style: italic;
    margin: 0 auto;
    cursor: pointer;
    position: relative;

    ${props => props.theme.mediaQuery('lg')} {
      font-size: ${rem(20)};
      letter-spacing: ${rem(2)};
    }

    ${props => props.theme.mediaQuery('lg', 'min')} {
      font-size: ${rem(28)};
      letter-spacing: ${rem(3)};
    }

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 1px;
      margin-top: ${rem(10)};
      background: currentColor;
      transform: scaleX(0);
      transition: transform 0.3s;
      opacity: 0.5;
    }

    &:hover:after {
      transform: scaleX(1);
    }
  }

  > *:not(:last-child) {
    ${props => props.theme.mediaQuery('lg')} {
      margin-bottom: ${rem(20)};
    }

    ${props => props.theme.mediaQuery('lg', 'min')} {
      margin-bottom: ${rem(48)};
    }
  }
`;

const FieldWrapper = styled.div`
  display: flex;

  label {
    font-family: ${props => props.theme.fonts.MrsEavesSmallCaps};
    font-size: ${rem(20)};
    line-height: ${rem(8)};
    color: ${props => props.theme.colors.red};
    margin-right: ${rem(16)};
    display: flex;
    align-items: flex-end;
    position: relative;
    top: -4px;
  }

  input {
    flex: 1;
    border: 0;
    border-bottom: 2px solid ${props => props.theme.colors.red};
    outline: 0;
    color: ${props => props.theme.colors.red};
    font-size: ${rem(16)};
    appearance: none;
    font-family: ${props => props.theme.fonts.Garamond};
  }
`;

const ModalSuccess = styled.div`
  text-align: center;

  span {
    display: block;
    margin: ${rem(48)} 0 ${rem(8)};
    font-style: italic;
    color: ${props => props.theme.colors.red};
    font-family: ${props => props.theme.fonts.Garamond};
    letter-spacing: ${rem(3)};
    font-size: ${rem(36)};
    text-transform: none;
    font-weight: 400;
  }

  p {
    max-width: ${rem(420)};
    font-size: ${rem(16)};
    letter-spacing: ${rem(5)};
    font-weight: 400;

    margin: 0 auto;
  }
`;

interface ContactModalProps {
  isOpen: boolean;
  closeModal?(...args: unknown[]): unknown;
}

export default function ContactModal({
  isOpen,
  closeModal,
}: ContactModalProps) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [formStatus, setFormStatus] = useState({
    isSubmitted: false,
    isError: false,
    isSuccess: false,
  });

  const encodeData = data =>
    Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');

  const onFormSubmit = e => {
    e.preventDefault();
    const data = { name, email, message };

    const form = e.target;

    const stringifiedData = encodeData({
      'form-name': form.getAttribute('name'),
      ...data,
    });

    setFormStatus({ ...formStatus, isSubmitted: true });

    fetch('/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: stringifiedData,
    })
      .then(() => {
        onFormSuccess();
      })
      .catch(() => onFormFailure());
  };

  const onFormSuccess = () =>
    setFormStatus({ ...formStatus, isSuccess: true, isSubmitted: true });

  const onFormFailure = () =>
    setFormStatus({ ...formStatus, isError: true, isSubmitted: false });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      closeTimeoutMS={300}
      style={customStyles}
      contentLabel="Contact Modal"
      appElement={
        typeof document !== 'undefined' && document.querySelector('#___gatsby')
      }
    >
      <CloseButton onClick={closeModal}>
        <MinusIcon />
      </CloseButton>

      <ModalWrapper>
        {!formStatus.isSuccess && (
          <div>
            <StaticImage
              src="../../static/images/form-illustration.png"
              alt="contact illustration"
            />
            <h3>Contact Form</h3>
          </div>
        )}

        {formStatus.isSuccess ? (
          <ModalSuccess>
            <StaticImage
              src="../../static/images/complete-illustration.png"
              alt="complete illustration"
              height={150}
            />
            <span>Thank you for contacting us!</span>
            <p>SOMEONE WILL BE IN TOUCH WITH YOU SHORTLY.</p>
          </ModalSuccess>
        ) : (
          <FormWrapper
            name="contact-form"
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            onSubmit={onFormSubmit}
          >
            <FieldWrapper>
              <label htmlFor="name">name:</label>
              <input
                type="text"
                id="name"
                required
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </FieldWrapper>

            <FieldWrapper>
              <label htmlFor="email">email:</label>
              <input
                type="email"
                id="email"
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </FieldWrapper>

            <textarea
              rows={6}
              placeholder="Message"
              required
              value={message}
              onChange={e => setMessage(e.target.value)}
            />

            <button type="submit">Submit Form</button>
          </FormWrapper>
        )}
      </ModalWrapper>

      <GlobalModalStyles />
    </Modal>
  );
}
