import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import content from '../../content/header.json';
import { Container } from '../../static/styles/helpers';
import { Button } from '../button';
import { ContactModal } from '../contact-modal';

const HeaderWrapper = styled(motion.header)<{ scrolled: boolean }>`
  height: ${rem(116)};
  background-color: ${props => props.theme.colors.red};
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  transition: background 0.3s;

  button {
    transition: background 0.3s;
  }

  ${props =>
    props.scrolled &&
    css`
      background: ${props => props.theme.colors.gray};

      ${props => props.theme.mediaQuery('lg', 'min')} {
        button {
          background: ${props => props.theme.colors.red};

          &:hover {
            background: ${props => props.theme.colors.redHover};
          }
        }
      }
    `}

  ${props => props.theme.mediaQuery('lg', 'max')} {
    height: ${rem(80)};
  }

  ${Container} {
    display: flex;
    align-items: center;
  }

  ul {
    margin: 0;
    list-style: none;
    padding: 0;

    ${props => props.theme.mediaQuery('lg', 'min')} {
      display: flex;
      height: 100%;
    }

    ${props => props.theme.mediaQuery('lg', 'max')} {
      align-self: center;
      width: 100%;
      padding-left: ${rem(20)};
      padding-right: ${rem(20)};
    }

    li {
      display: flex;
      align-items: center;
      height: 100%;
      position: relative;
      font-family: ${props => props.theme.fonts.MrsEavesSmallCaps};
      letter-spacing: ${rem(3)};
      color: ${props => props.theme.colors.white};
      font-size: ${rem(18)};
      text-transform: uppercase;
      cursor: pointer;

      &.dropdown-link {
        a::after {
          display: none;
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          background-color: ${props => props.theme.colors.white};
          transition: transform 0.25s ease-in-out;

          ${props => props.theme.mediaQuery('lg', 'min')} {
            top: 0;
            transform-origin: top;
            transform: scaleY(0);
            height: ${rem(5)};
          }

          ${props => props.theme.mediaQuery('lg', 'max')} {
            top: 100%;
            transform-origin: left;
            transform: scaleX(0);
            height: ${rem(2)};
          }
        }

        ${props => props.theme.mediaQuery('lg', 'min')} {
          &:hover:after {
            transform: none;
          }
        }
      }

      ${props => props.theme.mediaQuery('lg', 'min')} {
        // display: flex;
        // align-items: center;
      }

      ${props => props.theme.mediaQuery('lg', 'max')} {
        position: relative;
        font-size: ${rem(22)};
      }

      ${props => props.theme.mediaQuery('lg', 'max')} {
        display: block;
      }

      &:not(:last-child) {
        ${props => props.theme.mediaQuery('lg', 'min')} {
          margin-right: ${rem(24)};
        }

        ${props => props.theme.mediaQuery('lg', 'max')} {
          margin-bottom: ${rem(15)};
        }
      }
    }

    a {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        background-color: ${props => props.theme.colors.white};
        transition: transform 0.25s ease-in-out;

        ${props => props.theme.mediaQuery('lg', 'min')} {
          top: 0;
          transform-origin: top;
          transform: scaleY(0);
          height: ${rem(5)};
        }

        ${props => props.theme.mediaQuery('lg', 'max')} {
          top: 100%;
          transform-origin: left;
          transform: scaleX(0);
          height: ${rem(2)};
        }
      }

      &[aria-current='page'],
      &:hover {
        &:after {
          transform: none;
        }
      }
    }
  }

  a {
    color: ${props => props.theme.colors.white};
  }
`;

const LogoLink = styled(Link)`
  ${props => props.theme.mediaQuery('lg', 'max')} {
    width: ${rem(150)};
    position: relative;
    z-index: 2;
  }
`;

const Menu = styled.div<{ open: boolean }>`
  margin: 0 0 0 auto;
  height: 100%;
  display: flex;

  ${props => props.theme.mediaQuery('lg', 'max')} {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: 0s 0.5s;

    li {
      transform: translateX(${rem(-20)});
      opacity: 0;
      transition: 0.3s;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${props => props.theme.colors.red};
      transform-origin: top;
      transform: scaleY(0);
      transition: 0.5s;
    }

    ${props =>
      props.open &&
      css`
        left: 0;
        transition: 0s 0s;

        li {
          transform: translateX(0);
          opacity: 1;

          &:nth-of-type(1) {
            transition: 0.5s 0.3s;
          }

          &:nth-of-type(2) {
            transition: 0.5s 0.4s;
          }

          &:nth-of-type(3) {
            transition: 0.5s 0.5s;
          }

          &:nth-of-type(4) {
            transition: 0.5s 0.6s;
          }

          &:nth-of-type(5) {
            transition: 0.5s 0.7s;
          }

          &:nth-of-type(6) {
            transition: 0.5s 0.8s;
          }
        }

        &::before {
          transform: scaleY(1);
        }
      `}
  }
`;

const Burger = styled.button<{ open: boolean }>`
  width: ${rem(36)};
  height: ${rem(20)};
  position: relative;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  transition: 0.3s;
  margin-left: auto;
  top: ${rem(-5)};
  z-index: 3;
  background: none;

  ${props => props.theme.mediaQuery('lg', 'min')} {
    display: none;
  }

  span {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    background: ${props => props.theme.colors.white};

    &:nth-of-type(1) {
      transition:
        all 0.3s 0.3s,
        transform 0.3s 0s;
    }

    &:nth-of-type(2) {
      top: 50%;
      margin-top: -1px;
      left: 0;
      transition: all 0.3s 0.3s;
    }

    &:nth-of-type(3) {
      bottom: 0;
      top: auto;
      left: 0;
      transition:
        all 0.3s 0.3s,
        transform 0.3s 0s;
    }
  }

  ${props =>
    props.open &&
    css`
      span {
        &:nth-of-type(1) {
          top: 50%;
          margin-top: -1px;
          transform: rotate(45deg);
          transition:
            all 0.3s,
            transform 0.3s 0.3s;
        }

        &:nth-of-type(2) {
          opacity: 0;
          transition:
            all 0.3s,
            opacity 0.3s 0s;
        }

        &:nth-of-type(3) {
          bottom: 50%;
          margin-bottom: -1px;
          transform: rotate(-45deg);
          transition:
            all 0.3s,
            transform 0.3s 0.3s;
        }
      }
    `}
`;

const DropdownContent = styled.span`
  display: flex;
  padding-top: ${rem(10)};
  will-change: transform;

  ${props => props.theme.mediaQuery('lg', 'min')} {
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(${rem(-10)});
    opacity: 0;
    transition:
      transform 0.3s,
      opacity 0.3s;
  }

  a {
    white-space: nowrap;
    font-style: italic;
    text-transform: none;
    opacity: 0.32;
    padding: 0 10px;
    position: relative;
    transition: opacity 0.3s;

    &:hover,
    &[aria-current='page'] {
      opacity: 1;
    }

    &:not(:last-of-type) {
      &::before {
        content: '│';
        position: absolute;
        right: 0;
        top: 50%;
        margin-right: -10px;
        transform: translateY(-50%);
      }
    }

    &::after {
      display: none;
    }
  }
`;

const Dropdown = styled.span`
  display: inline-block;
  vertical-align: top;
  position: relative;

  ${props => props.theme.mediaQuery('lg', 'min')} {
    &:hover {
      ${DropdownContent} {
        transform: translateX(-50%) translateY(${rem(-5)});
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
`;

export default function Header() {
  const [modalIsOpen, setIsOpen] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const checkPosition = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const onContactClick = () => {
    modalIsOpen ? closeModal() : openModal();
  };

  useEffect(() => {
    window.addEventListener('scroll', checkPosition);

    checkPosition();
  });

  return (
    <HeaderWrapper
      scrolled={scrolled}
      initial={{
        y: -20,
        opacity: 0,
      }}
      animate={{
        y: 0,
        opacity: 1,
        transition: { duration: 0.5, delay: 0, ease: [0.24, 0.1, 0.25, 1] },
      }}
    >
      <Container>
        <LogoLink
          to="/"
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <StaticImage
            src="../../static/images/logo-white.png"
            height={80}
            alt="OCAA logo"
            placeholder="none"
          />
        </LogoLink>

        <Burger
          open={menuOpen}
          aria-label="Toggle menu"
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        >
          <span />
          <span />
          <span />
        </Burger>

        <Menu open={menuOpen}>
          <ul>
            {content.links.map((link, index) => {
              const isLast = index === content.links.length - 1;
              const hasDropdown = typeof link.dropdown != 'undefined';

              return (
                <li
                  className={hasDropdown ? 'dropdown-link' : ''}
                  key={`${link.slug}.${index}`}
                >
                  {hasDropdown ? (
                    <Dropdown>
                      <Link to={link.dropdown[0].slug}>{link.title}</Link>
                      <DropdownContent>
                        {link.dropdown.map(item => (
                          <Link
                            to={item.slug}
                            key={item.title}
                            onClick={() => {
                              setMenuOpen(false);
                            }}
                          >
                            {item.title}
                          </Link>
                        ))}
                      </DropdownContent>
                    </Dropdown>
                  ) : (
                    <Link
                      to={link.slug}
                      key={link.slug}
                      onClick={e => {
                        setMenuOpen(false);

                        if (isLast) {
                          e.preventDefault();
                          onContactClick();
                        }
                      }}
                    >
                      {isLast ? <Button title={link.title} /> : link.title}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </Menu>
      </Container>
      <ContactModal isOpen={modalIsOpen} closeModal={closeModal} />;
    </HeaderWrapper>
  );
}
