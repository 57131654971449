import { StaticImage } from 'gatsby-plugin-image';
import { rem } from 'polished';
import styled from 'styled-components';

import content from '../../content/footer.json';

import { Col, Row } from '@/components/grid';

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.colors.gray};
  font-family: ${props => props.theme.fonts.MrsEavesSmallCaps};
  position: relative;
  padding-bottom: ${rem(100)};
  padding-top: ${rem(150)};

  ${props => props.theme.mediaQuery('lg', 'max')} {
    padding-bottom: 0;
  }

  .crest {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    z-index: 1;
    overflow: visible;

    &::before {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: ${props => props.theme.colors.white};
      z-index: 0;
      width: ${rem(150)};
      height: ${rem(150)};
      border-radius: 50%;
      border: 2px solid ${props => props.theme.colors.red};
    }
  }
`;

interface FooterTextProps {
  letterSpacingValue: number;
}

const FooterText = styled.span<FooterTextProps>`
  font-family: ${props => props.theme.fonts.MrsEavesSmallCaps};
  letter-spacing: ${props => rem(props.letterSpacingValue)};
  color: ${props => props.theme.colors.white};
  font-size: ${rem(16)};
  margin-bottom: ${rem(10)};
  text-transform: uppercase;
`;

const InfoLeftCol = styled(Col)`
  background: ${props => props.theme.colors.red};
  padding: ${rem(50)};
  color: ${props => props.theme.colors.white};
  font-size: ${rem(20)};
  font-family: ${props => props.theme.fonts.Garamond};
  text-align: center;
  display: flex;

  .gatsby-image-wrapper {
    align-self: center;
    margin: auto auto;
  }

  ${props => props.theme.mediaQuery('lg', 'max')} {
    border-radius: 0 8px 8px 0;
  }
`;

const FooterInfoRight = styled(Col)`
  background: ${props => props.theme.colors.red};
  padding: ${rem(50)};
  color: ${props => props.theme.colors.white};
  font-size: ${rem(20)};
  font-family: ${props => props.theme.fonts.Garamond};

  ${props => props.theme.mediaQuery('lg', 'max')} {
    border-radius: 8px 0 0 8px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    margin-top: ${rem(50)};

    li {
      align-self: center;

      &:not(:first-of-type) {
        margin-left: ${rem(20)};
      }

      a {
        display: inline-block;
        vertical-align: top;
        transition:
          transform 0.3s,
          opacity 0.3s;
      }

      a:hover {
        transform: translateY(${rem(-3)});
        opacity: 0.8;
      }
    }
  }
`;

const CenterCol = styled(Col)`
  text-align: center;

  span {
    display: block;
    margin: 0 auto;
  }

  ${props => props.theme.mediaQuery('lg', 'max')} {
    padding: ${rem(30)} 0;
  }
`;

const RowContainer = styled.div`
  overflow: hidden;
`;

export default function Footer() {
  return (
    <FooterWrapper>
      <StaticImage
        alt="cross"
        src="../../static/images/bottomcross.png"
        height={110}
        className="crest"
      />

      <RowContainer>
        <Row>
          <InfoLeftCol lg={4} xs={12}>
            <StaticImage
              alt="cross"
              src="../../static/images/sealofep.png"
              height={160}
            />
          </InfoLeftCol>

          <CenterCol lg={4} xs={12}>
            {content.paragraph.map((text, index) => (
              <FooterText
                key={text}
                letterSpacingValue={index === 2 ? 18 : index === 3 ? 2 : 4}
              >
                {text}
              </FooterText>
            ))}
          </CenterCol>

          <Col lg={4} xs={12}>
            <FooterInfoRight>
              <p>
                To donate, please send checks to our address:
                <br />
                <br />
                Attn: OCAA
                <br />
                455 Quail Ridge Rd,
                <br />
                Salem, SC 29676
                <br />
                <br />
                <em>Paypal & Other donation platforms coming soon</em>
              </p>

              <ul>
                <li>
                  <a
                    href="https://www.instagram.com/orthodoxacademy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <StaticImage
                      alt="Instagram"
                      src="../../static/images/instagramlogo.png"
                      height={30}
                    />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.youtube.com/channel/UCpi8BK0D5oWy0Ux-3Mse6NQ/featured"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <StaticImage
                      alt="Youtube"
                      src="../../static/images/youtubelogo.png"
                      height={25}
                    />
                  </a>
                </li>
              </ul>
            </FooterInfoRight>
          </Col>
        </Row>
      </RowContainer>
    </FooterWrapper>
  );
}
