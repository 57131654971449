import { DefaultTheme } from 'styled-components';

import mediaQuery from './breakpoints';
import colors from './colors';
import fonts from './fonts';

const grid = {
  flexboxgrid: {
    gridSize: 12,
    gutterWidth: 1.25,
    outerMargin: 2,
    mediaQuery: 'only screen',
    breakpoints: {
      xs: 0,
      sm: 36,
      md: 48,
      lg: 62,
    },
  },
};

export default {
  ...grid,
  colors,
  fonts,
  mediaQuery,
} as DefaultTheme;
