import { rem } from 'polished';
import styled from 'styled-components';

export default styled.button`
  width: ${rem(28)};
  height: ${rem(28)};
  background-color: ${props => props.theme.colors.red};
  border-radius: 50%;
  position: absolute;
  top: 16px;
  left: 24px;
  cursor: pointer;
  padding: 0;
  appearance: none;
  display: flex;

  svg {
    margin: 0 auto;
    align-self: center;
  }
`;
