import { rem } from 'polished';
import { createGlobalStyle } from 'styled-components';

const GlobalModalStyles = createGlobalStyle`
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.3s;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;

    .ReactModal__Content {
      opacity: 1;
      margin-top: 0;
    }
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;

    .ReactModal__Content {
      opacity: 0;
      margin-top: ${rem(20)};
      transition: opacity 0.3s 0s, margin-top 0.3s 0s;
    }
  }

  .ReactModal__Content {
    opacity: 0;
    margin-top: ${rem(20)};
    transition: opacity 0.3s 0.2s, margin-top 0.3s 0.2s;
  }
`;

export default GlobalModalStyles;
